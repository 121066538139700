.container {
    margin-bottom: 5em;
}

.red-BG {
    background-color: #872200;
}

.light-white {
    background-color: #ffffff;
    padding: 10px 20px;
}

.light-BG {
    background-color: #ffffffa2;
    padding: 10px 20px;
}

.light-BG2 {
    background-color: #c5dbdd;
}

/* 
.section-wrapper {
    margin-bottom: 100px;
} */