@import url(https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap);
/* colors */
/* 
dark peacock: #043133
peachock: #095C60
light: #F1F1E6
orange: #FF8945
teal: #97DDE1 */

/* Dust Palette */
/* 
peacock: #095C60
light grey green: #94B0B2
mid grey green: #617C7D
lavender: #8181A8 
brick: #872200 */

body {
  font-family: 'EB Garamond', serif;
  margin: 0;
  background-image: url("https://ajf-general-images.s3-us-west-2.amazonaws.com/jane-k/bird-BG-threshold-opacity.png");
  background-repeat: space;
  background-size: 50%;
  background-position: center;
  background-color: #002C30;
  /* background-color: #F1F1E6; */
  height: 100%;
  color: #001c1f;
}

.light-text p, 
.light-text h2,
.light-text h3 {
  color: #c5dbdd!important;
}

.nav-link-color,
.brand-text {
  color: #e2edee!important;
}

h1, h2, h3, h4, h5, h6 {
  color: #001c1f;
}

p {
  color: #001c1f;
  font-family: 'Noto Sans JP', sans-serif;
}

.text-center {
  text-align: center;
}

.serif-text {
  font-family: 'EB Garamond', serif;
}

.sans-text {
  font-family: 'Noto Sans JP', sans-serif;
}

.footer-text {
  color: #001c1f!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page {
  min-height: 100vh;
}

@media screen and (max-width: 600px) {
  body {
    background-size: 100%;
  }
}
nav {
    background-color: #872200!important;
}

.logo {
    padding-top: 8px!important;
    padding-left: 8px!important;
}

.brand-text {
    padding-left: 8px!important;
}

li a {
    font-family: 'Noto Sans JP', sans-serif!important;
}

@media screen and (max-width: 992px) {
    .brand-text {
        padding-left: 0px!important;
    }
}

@media screen and (max-width: 392px) {
    .brand-text {
        display: none!important;
    }
}
.parallax {
    position: relative;
    text-align: center;
    background-image: linear-gradient(rgb(255, 255, 255), black, black);
  }

.parallax img {
    opacity: 0.6!important;
}

.parallax h2 {
    color: #e2edee!important;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    }

.container {
    margin-bottom: 5em;
}

.red-BG {
    background-color: #872200;
}

.light-white {
    background-color: #ffffff;
    padding: 10px 20px;
}

.light-BG {
    background-color: #ffffffa2;
    padding: 10px 20px;
}

.light-BG2 {
    background-color: #c5dbdd;
}

/* 
.section-wrapper {
    margin-bottom: 100px;
} */
/* .container {
    margin: 20px auto;
} */

.italic {
    font-style: italic;
}

.white-BG {
    background-color: #ffffff;
}

.portait {
    width: 100%;
}
.contact-card {
    background-color: #ffffff81;
}

.link-color {
    color: #872200!important;
}

.bulleted-list {
    margin-left: 20px;
}

.bulleted-list li {
    list-style-type: disc!important;
    list-style-type: initial!important;
    color: #002C30;
}

.footer-text {
    text-align: center;
}

@media screen and (max-width: 392px) {
    .bird {
        display: none;
    }
  }
