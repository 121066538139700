@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');

/* colors */
/* 
dark peacock: #043133
peachock: #095C60
light: #F1F1E6
orange: #FF8945
teal: #97DDE1 */

/* Dust Palette */
/* 
peacock: #095C60
light grey green: #94B0B2
mid grey green: #617C7D
lavender: #8181A8 
brick: #872200 */

body {
  font-family: 'EB Garamond', serif;
  margin: 0;
  background-image: url("https://ajf-general-images.s3-us-west-2.amazonaws.com/jane-k/bird-BG-threshold-opacity.png");
  background-repeat: space;
  background-size: 50%;
  background-position: center;
  background-color: #002C30;
  /* background-color: #F1F1E6; */
  height: 100%;
  color: #001c1f;
}

.light-text p, 
.light-text h2,
.light-text h3 {
  color: #c5dbdd!important;
}

.nav-link-color,
.brand-text {
  color: #e2edee!important;
}

h1, h2, h3, h4, h5, h6 {
  color: #001c1f;
}

p {
  color: #001c1f;
  font-family: 'Noto Sans JP', sans-serif;
}

.text-center {
  text-align: center;
}

.serif-text {
  font-family: 'EB Garamond', serif;
}

.sans-text {
  font-family: 'Noto Sans JP', sans-serif;
}

.footer-text {
  color: #001c1f!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page {
  min-height: 100vh;
}

@media screen and (max-width: 600px) {
  body {
    background-size: 100%;
  }
}