nav {
    background-color: #872200!important;
}

.logo {
    padding-top: 8px!important;
    padding-left: 8px!important;
}

.brand-text {
    padding-left: 8px!important;
}

li a {
    font-family: 'Noto Sans JP', sans-serif!important;
}

@media screen and (max-width: 992px) {
    .brand-text {
        padding-left: 0px!important;
    }
}

@media screen and (max-width: 392px) {
    .brand-text {
        display: none!important;
    }
}